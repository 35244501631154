// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('click', '#load-readme', function (event) {
  const editor = document.querySelector<HTMLTextAreaElement>('#gollum-editor-body')!
  const buffer = document.querySelector<HTMLElement>('#editor-body-buffer')!
  const undoLink = document.querySelector<HTMLElement>('#undo-load-readme')!

  const readmeText = buffer.textContent
  const readmeName = event.currentTarget.getAttribute('data-readme-name')!
  swapEditorAndBufferValues(editor, buffer)

  if (!(event.currentTarget instanceof HTMLButtonElement)) return
  event.currentTarget.disabled = true
  event.currentTarget.textContent = `${readmeName} loaded`
  /* eslint-disable-next-line github/no-d-none */
  undoLink.classList.remove('d-none')

  function hideUndoLinkIfChanged() {
    if (editor.value !== readmeText) {
      /* eslint-disable-next-line github/no-d-none */
      undoLink.classList.add('d-none')
      editor.removeEventListener('input', hideUndoLinkIfChanged)
    }
  }

  editor.addEventListener('input', hideUndoLinkIfChanged)
})

on('click', '#undo-load-readme', function (event) {
  const editor = document.querySelector<HTMLTextAreaElement>('#gollum-editor-body')!
  const buffer = document.querySelector<HTMLElement>('#editor-body-buffer')!

  swapEditorAndBufferValues(editor, buffer)
  const loadReadmeButton = document.querySelector<HTMLButtonElement>('#load-readme')!
  const readmeName = loadReadmeButton.getAttribute('data-readme-name')!

  loadReadmeButton.disabled = false
  // eslint-disable-next-line i18n-text/no-en
  loadReadmeButton.textContent = `Load ${readmeName}`
  /* eslint-disable-next-line github/no-d-none */
  event.currentTarget.classList.add('d-none')
})

function swapEditorAndBufferValues(editor: HTMLTextAreaElement, editorBodyBuffer: HTMLElement) {
  const oldEditorVal = editor.value
  editor.value = editorBodyBuffer.textContent || ''
  editorBodyBuffer.textContent = oldEditorVal
}
