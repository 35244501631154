import {controller, target} from '@github/catalyst'

@controller
class ActionsPolicyPopoverElement extends HTMLElement {
  @target popoverEl: HTMLElement

  override togglePopover() {
    this.popoverEl.hidden = !this.popoverEl.hidden
    return true
  }
}
