// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function selectRepoItem(event: Event) {
  const item = event.target as HTMLInputElement
  const form = item.closest<HTMLElement>('.js-policy-repos-container')!.querySelector('.js-policy-repos-form')!
  const dataFormFieldName = form.getAttribute('data-form-field-name')
  const selectedRepoInput = createRepoInput(item, dataFormFieldName)
  const existing = form.querySelector(`[value='${selectedRepoInput.value}']`)
  if (existing) {
    existing.replaceWith(selectedRepoInput)
  } else {
    form.append(selectedRepoInput)
  }
}

function createRepoInput(item: HTMLInputElement, dataFormFieldName: string | null): HTMLInputElement {
  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = item.checked ? 'enable[]' : 'disable[]'
  input.value = item.value

  if (dataFormFieldName) {
    input.setAttribute('data-form-field-name', dataFormFieldName)
  }

  return input
}

on('change', '.js-policy-repos-container .js-repository-item-checkbox', selectRepoItem)
