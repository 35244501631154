import type FilterInputElement from '@github/filter-input-element'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

observe('.js-configure-token-sso', function initialize(el) {
  const filterEl = el as FilterInputElement
  filterEl.filter = filterList
})

on('filter-input-updated', '.js-configure-token-sso', checkEmptyState)

function filterList(element: HTMLElement, elementText: string, query: string) {
  let match = false

  if (element.hasAttribute('data-filter-header')) {
    // always display authorization headings
    match = true
  } else if (element.querySelector('[data-filter-item-text]')) {
    // match filter element text to query
    const itemText = element.querySelector('[data-filter-item-text]')!.textContent || ''
    match = itemText.toLowerCase().indexOf(query.toLowerCase()) !== -1
  }

  return {match, hideNew: true}
}

function checkEmptyState(event: CustomEvent) {
  const filter = event.currentTarget as FilterInputElement
  const filterParent = filter.parentElement as HTMLElement
  // toggle header and no results visibility based on filter results
  toggleHeader('available', filterParent)
  toggleHeader('unavailable', filterParent)
  toggleNoResults(filterParent)
}

function toggleHeader(availability: string, filterParent: HTMLElement) {
  const header = filterParent.querySelector(`[data-filter-header=${availability}]`) as HTMLDivElement
  if (!header) return

  if (filterParent.querySelector(`[data-sso-availability=${availability}]:not([hidden])`)) {
    // show header if there are any unhidden items of that availability
    header.hidden = false
  } else {
    // hide header if there are no unhidden items of that availability
    header.hidden = true
  }
}

function toggleNoResults(filterParent: HTMLElement) {
  const noResults = filterParent.querySelector('[data-filter-empty-state]') as HTMLDivElement
  if (!noResults) return

  if (filterParent.querySelector('[data-sso-availability]:not([hidden])')) {
    // hide no results message if there are any unhidden items
    noResults.hidden = true
  } else {
    // hide no results message if there are no unhidden items
    noResults.hidden = false
  }
}
