import memoize from '@github/memoize'
import {
  FetchDataEvent,
  FilterItem,
  type FilterProvider,
  type QueryEvent,
  type QueryFilterElement,
} from '@github-ui/query-builder-element/query-builder-api'
import type {QueryBuilderElement} from '@github-ui/query-builder-element'

import {hasMatch, score} from 'fzy.js'

interface Suggestion {
  value: string
  name?: string
  description?: string
}

type BaseProviderProps = {
  queryBuilder: QueryBuilderElement
  name: string
  value: string
  description: string
  priority: number
  suggestionConfigurationAttribute: string | null
  suggestionConfigurationPath: string | null
}

const memoizeCache = new Map()
const memoizeFetchJSON = memoize(fetchJSON, {cache: memoizeCache})

async function fetchJSON(url: string) {
  const response = await fetch(url, {headers: {Accept: 'application/json'}})

  if (response.ok) {
    return await response.json()
  } else {
    return undefined
  }
}

function filterSuggestions(suggestions: Suggestion[], query: string): Suggestion[] {
  // no need to filter if we don't have a query
  if (query === '') return suggestions

  const filteredSuggestions = suggestions.filter(suggestion => {
    if (hasMatch(query, suggestion.value)) return suggestion
    if (suggestion.description != null && hasMatch(query, suggestion.description)) return suggestion
  })

  filteredSuggestions.sort((a, b) => {
    const aValueMatch = hasMatch(query, a.value)
    const bValueMatch = hasMatch(query, b.value)

    // we want to prefer values matches over description matches
    // if both suggestions have a value match, we use score to sort
    if (aValueMatch && bValueMatch) {
      return score(query, a.value) - score(query, b.value)
    } else if (aValueMatch && !bValueMatch) {
      // prefer a's value since it matches
      return -1
    } else if (!aValueMatch && bValueMatch) {
      //  prefer b's value since it matches
      return 1
    } else {
      // if neither suggestion has a value match that implies that both are matched on description
      return score(query, a.description!) - score(query, b.description!)
    }
  })

  // return in descending order
  return filteredSuggestions.reverse()
}

class BaseProvider extends EventTarget implements FilterProvider {
  type = 'filter' as const
  protected queryBuilder: QueryBuilderElement
  readonly name: string
  readonly value: string
  readonly singularItemName: string
  readonly description: string
  readonly suggestionConfigurationAttribute: string | null
  readonly suggestionConfigurationPath: string | null
  readonly priority: number

  constructor({
    name = '',
    value = '',
    description = '',
    suggestionConfigurationAttribute = null,
    suggestionConfigurationPath = null,
    priority = 0,
    queryBuilder,
  }: BaseProviderProps) {
    super()
    this.suggestionConfigurationAttribute = suggestionConfigurationAttribute
    this.suggestionConfigurationPath = suggestionConfigurationPath

    // if we don't have a suggestion configuration, that
    // means we shouldn't add this provider to the QueryBuilder
    // (for example, this prevents the manifest provider being show for org rules)
    if (this.hasSuggestionConfiguration()) {
      this.queryBuilder = queryBuilder
      this.name = name
      this.value = value
      this.description = description
      this.singularItemName = value
      this.priority = priority

      this.queryBuilder.attachProvider(this)
      this.queryBuilder.addEventListener('query', this)

      // preload the suggestions to avoid lag when user interacts with the QueryBuilder
      this.fetchSuggestions()
    }
  }

  fetchSuggestions(): Promise<Suggestion[]> {
    if (this.suggestionConfigurationAttribute != null) {
      return JSON.parse(this.getSearchInput().getAttribute(this.suggestionConfigurationAttribute)!)
    } else {
      return memoizeFetchJSON(this.getSearchInput().getAttribute(this.suggestionConfigurationPath!)!)
    }
  }

  hasSuggestionConfiguration(): boolean {
    if (this.getSearchInput() === null) return false

    if (this.suggestionConfigurationAttribute != null) {
      return this.getSearchInput().getAttribute(this.suggestionConfigurationAttribute) != null
    } else if (this.suggestionConfigurationPath != null) {
      return this.getSearchInput().getAttribute(this.suggestionConfigurationPath) != null
    } else {
      return false
    }
  }

  getSearchInput(): HTMLElement {
    return document.querySelector<HTMLElement>('query-builder#query-builder-rule-criteria-input-combobox')!
  }

  async handleEvent(event: QueryEvent) {
    const lastQuery = event.parsedQuery.at(-1)
    const lastQueryValue = lastQuery?.value || ''
    const lastQueryType = lastQuery?.type
    const lastQueryFilter = (lastQuery as QueryFilterElement)?.filter || ''

    if (lastQueryType !== 'filter' && (hasMatch(lastQueryValue, this.value) || lastQueryValue === '')) {
      this.dispatchEvent(new Event('show'))
    }

    if (lastQueryType !== 'filter' || lastQueryFilter !== this.value) return

    const suggestions = await this.fetchSuggestions()
    this.dispatchEvent(new FetchDataEvent(this.fetchSuggestions()))

    const filteredSuggestions = filterSuggestions(suggestions, lastQueryValue)

    // The search filter has a performance bug triggered by the number of suggestions we return
    // The fix for now is to limit the number of suggestions to a smallish number
    // See https://github.com/github/discussions/issues/2816
    for (const suggestion of filteredSuggestions.slice(0, 10)) {
      const filterItem = new FilterItem({
        filter: this.singularItemName,
        name: suggestion.name,
        value: suggestion.value.replace(/"/g, ''), // Remove quotes for some reason, unclear why we need this
        description: suggestion.description,
        priority: 1,
      })
      this.dispatchEvent(filterItem)
    }
  }
}

class SeverityProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Severities',
      value: 'severity',
      description: 'critical, high, moderate, low',
      priority: 1,
      suggestionConfigurationAttribute: 'data-suggestable-severities',
      suggestionConfigurationPath: null,
    })
  }
}

class PackageProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Packages',
      value: 'package',
      description: 'package-name',
      priority: 2,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-packages-path',
    })
  }
}

class EcosystemProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Ecosystems',
      value: 'ecosystem',
      description: 'ecosystem-name',
      priority: 3,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-ecosystems-path',
    })
  }
}

class ScopeProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Scopes',
      value: 'scope',
      description: 'runtime, development',
      priority: 4,
      suggestionConfigurationAttribute: 'data-suggestable-scopes',
      suggestionConfigurationPath: null,
    })
  }
}

class ManifestProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'Manifests',
      value: 'manifest',
      description: 'manifest-name',
      priority: 5,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-manifests-path',
    })
  }
}

class CWEProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'CWEs',
      value: 'cwe',
      description: 'cwe-number',
      priority: 6,
      suggestionConfigurationAttribute: 'data-suggestable-cwes',
      suggestionConfigurationPath: null,
    })
  }
}

class CVEProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'CVE IDs',
      value: 'cve_id',
      description: 'cve-id',
      priority: 7,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-cve-ids-path',
    })
  }
}

class GHSAProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'GHSA IDs',
      value: 'ghsa_id',
      description: 'ghsa-id',
      priority: 8,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-ghsa-ids-path',
    })
  }
}

class EPSSProvider extends BaseProvider {
  constructor(queryBuilder: QueryBuilderElement) {
    super({
      queryBuilder,
      name: 'EPSS values',
      value: 'epss',
      description: 'epss',
      priority: 9,
      suggestionConfigurationAttribute: null,
      suggestionConfigurationPath: 'data-suggestable-epss-path',
    })
  }
}

document.addEventListener('query-builder:request-provider', (event: Event) => {
  const targetId = 'query-builder-rule-criteria-input-combobox'
  const target: QueryBuilderElement | null = event.target as QueryBuilderElement
  // only attach to our QueryBuilder
  if (!target || target.id !== targetId) return

  new SeverityProvider(target)
  new PackageProvider(target)
  new EcosystemProvider(target)
  new ScopeProvider(target)
  new ManifestProvider(target)
  new CWEProvider(target)
  new CVEProvider(target)
  new GHSAProvider(target)
  new EPSSProvider(target)
})
