// Add and remove Callback URLs for a GitHub App

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

function replaceEndWith(value: string, separator: string, replacement: string): string {
  const parts = value.split(separator)
  parts[parts.length - 1] = replacement
  return parts.join(separator)
}

// Adds the `required` class to the form-group container of all callback URL
// fields to indicate that the field is now required.
export function markCallbackUrlsRequired(required: boolean) {
  const container = document.querySelector<HTMLElement>('.js-application-callback-urls')
  if (!container) return

  const formGroups = container.querySelectorAll<HTMLElement>('.js-application-callback-url')
  const formGroupsLength = formGroups.length
  if (formGroupsLength === 0) return

  for (const field of formGroups) {
    if (required) {
      field.classList.add('required')
    } else {
      field.classList.remove('required')
    }
  }
}

// Delete the ApplicationCallbackURL or the Hook if their
// respective url input has been cleared out.
on('change', 'input.js-application-callback-url-field, input.js-hook-url-field', function ({currentTarget}) {
  let hiddenInputId = currentTarget.id
  hiddenInputId = replaceEndWith(hiddenInputId, '_', '_destroy')

  const hiddenInput = document.getElementById(hiddenInputId)

  if (hiddenInput instanceof HTMLInputElement) {
    const inputValue = (currentTarget as HTMLInputElement).value

    if (inputValue === '') {
      hiddenInput.value = 'true'
    } else {
      hiddenInput.value = 'false'
    }
  }
})

on('click', '.js-add-application-callback-url', function (event) {
  event.preventDefault()

  const container = document.querySelector<HTMLElement>('.js-application-callback-urls')
  if (!container) return

  const template = container.querySelector<HTMLTemplateElement>('template.js-new-application-callback-url')
  if (!template) return

  container.insertAdjacentHTML('beforeend', template.innerHTML.replace(/TEMPLATE_INDEX/g, Date.now().toString()))
})

on('click', '.js-delete-application-callback-url', function (event) {
  event.preventDefault()
  const currentTarget = event.currentTarget

  const formGroup = currentTarget.closest<HTMLElement>('.js-application-callback-url')
  if (!formGroup) return

  const formInput = formGroup.querySelector<HTMLInputElement>('input')
  if (!formInput) return

  const container = document.querySelector<HTMLElement>('.js-application-callback-urls')
  if (!container) return

  const formGroups = container.querySelectorAll<HTMLElement>('.js-application-callback-url')

  if (formGroups.length > 1) {
    formGroup.remove()
  } else {
    formInput.value = ''
  }

  let hiddenInputId = formInput.id
  hiddenInputId = replaceEndWith(hiddenInputId, '_', '_destroy')

  const hiddenInput = document.getElementById(hiddenInputId)
  if (!(hiddenInput instanceof HTMLInputElement)) return

  hiddenInput.value = 'true'
})
