// PATs v2: toggleable collapse and expand for permissions and token summary containers
// works with app/components/programmatic_access/collapsible_box_component.rb

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

export const enum ToggleState {
  Close = 'close',
  Open = 'open',
}

function toggleContainerDisplay(container: HTMLElement, toggleState: string) {
  const currentToggle = container.querySelector<SVGElement>(`[data-toggle-action=${toggleState}]`)!

  /* eslint-disable-next-line github/no-d-none */
  currentToggle.classList.add('d-none')
  updateCollapsibleContainerStyles(container, toggleState)

  if (toggleState === ToggleState.Close) {
    const hiddenToggle = container.querySelector<SVGElement>(`[data-toggle-action=${ToggleState.Open}]`)!

    /* eslint-disable-next-line github/no-d-none */
    hiddenToggle.classList.remove('d-none')
  } else {
    const hiddenToggle = container.querySelector<SVGElement>(`[data-toggle-action=${ToggleState.Close}]`)!

    /* eslint-disable-next-line github/no-d-none */
    hiddenToggle.classList.remove('d-none')
  }
}

export function updateCollapsibleContainerStyles(container: HTMLElement, toggleState: string) {
  const box = container.querySelector<HTMLDivElement>('summary .Box')!
  const body = container.querySelector<HTMLDivElement>('.js-collapsible-body')!

  // only use open styling if there are rows to display
  // permission summary containers will always have one hidden template li
  const rowCount = container.querySelectorAll<HTMLLIElement>('li:not([hidden])').length
  const containerOpen = toggleState === ToggleState.Open

  if (containerOpen && rowCount > 0) {
    // style fully open container
    box.classList.remove('rounded-bottom-2')
    box.classList.add('rounded-bottom-0')
    body.classList.remove('border-0')
  } else {
    // style closed or empty container
    box.classList.add('rounded-bottom-2')
    box.classList.remove('rounded-bottom-0')

    // hide body borders for the hidden summary template
    if (body.querySelector('[data-permission-template]')) body.classList.add('border-0')
  }
}

function handleDetailsToggle(event: Event) {
  const target = event.currentTarget as HTMLElement
  const action = target.hasAttribute('open') ? ToggleState.Open : ToggleState.Close
  toggleContainerDisplay(target, action)
}

on('toggle', '.js-collapsible-container', handleDetailsToggle, {capture: true})
