import type AutocompleteElement from '@github/auto-complete-element'
import {addThrottledInputEventListener} from '../throttled-input'
import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

on('change', '.js-repo-features-form input[type=checkbox]', function ({currentTarget}) {
  const repoOption = currentTarget.closest<HTMLElement>('.js-repo-option')!
  const indicator = repoOption.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')
})

function setMergeQueueSettings(protectedBranchNameInput: Element) {
  const mergeQueueCheckbox = document.querySelector<HTMLInputElement>('.js-protected-branch-merge-queue-checkbox')
  const mergeQueueSettings = document.querySelector<HTMLInputElement>('.js-protected-branch-merge-queue-settings')
  const mergeQueueWarning = document.querySelector<HTMLInputElement>('.js-protected-branch-name-merge-queue-warning')
  const protectedBranchNamePattern = (protectedBranchNameInput as HTMLInputElement).value

  if (mergeQueueCheckbox && mergeQueueSettings && mergeQueueWarning) {
    const mergeQueueCheckboxLabel = mergeQueueCheckbox.closest<HTMLElement>('.form-checkbox')!
    const mergeQueueShouldBeDisabled = protectedBranchNamePattern.includes('*')

    mergeQueueCheckbox.disabled = mergeQueueShouldBeDisabled
    mergeQueueWarning.hidden = !mergeQueueShouldBeDisabled
    mergeQueueCheckboxLabel.classList.toggle('color-fg-muted', mergeQueueShouldBeDisabled)

    if (mergeQueueShouldBeDisabled && mergeQueueCheckbox.checked) {
      alert(mergeQueueCheckbox.getAttribute('data-auto-uncheck-message'))
      mergeQueueSettings.hidden = mergeQueueShouldBeDisabled
      mergeQueueCheckbox.checked = false
    }
  }
}

// Set merge queue settings on page load
observe('.js-branch-name-pattern', setMergeQueueSettings)

on('change', '.js-branch-name-pattern', function ({currentTarget}) {
  setMergeQueueSettings(currentTarget as HTMLInputElement)
})

remoteForm('.js-repo-features-form', async function (form, wants) {
  let response
  try {
    response = await wants.html()
  } catch {
    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')
      const option = el.closest<HTMLElement>('.js-repo-option')!
      const checkbox = option.querySelector<HTMLInputElement>('input[type=checkbox]')!
      checkbox.checked = !checkbox.checked
    }
    return
  }
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }

  document.querySelector<HTMLElement>('.js-repo-nav')!.replaceWith(response.html)
})

on('change', '.js-merge-features-form input[type=checkbox]', function ({currentTarget}) {
  const form = (currentTarget as HTMLInputElement).form!

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }

  // Show loading indicator.
  const option = currentTarget.closest<HTMLElement>('.js-repo-option')!
  const indicator = option.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')
})

remoteForm('.js-merge-features-form', async function (form, wants) {
  try {
    await wants.text()
  } catch (error) {
    const warningSelector =
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      error.response && error.response.text.endsWith('(protected_branch_policy)')
        ? '.js-no-merge-commit-warning'
        : '.js-select-one-warning'
    for (const el of form.querySelectorAll(warningSelector)) {
      if (el instanceof HTMLElement) {
        el.hidden = false
      }
    }

    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')

      // Show error message.
      const option = el.closest<HTMLElement>('.js-repo-option')!
      option.classList.add('errored')

      const checkbox = option.querySelector<HTMLInputElement>('input[type=checkbox]')!
      checkbox.checked = !checkbox.checked

      // If the checkbox has errored but is still selected,
      // the additional 'on' class is also required.
      if (option) {
        option.classList.add('on')
      }
    }

    // Clear all success indicators.
    for (const el of form.querySelectorAll('.status-indicator-success')) {
      el.classList.remove('status-indicator-success')
    }

    return
  }

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }
  for (const el of form.querySelectorAll('.js-select-one-warning, .js-no-merge-commit-warning')) {
    if (el instanceof HTMLElement) {
      el.hidden = true
    }
  }

  // Show success indicator.
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }
})

remoteForm('.js-push-settings-form', async function (form, wants) {
  const checkbox = form.querySelector<HTMLInputElement>('.js-push-settings-form input[type=checkbox]')!
  const checked = checkbox.checked

  const numericContainer = form.querySelector<HTMLInputElement>('.js-push-settings-form div.js-form-numeric')!
  const numericInput = numericContainer.querySelector<HTMLInputElement>('input[type=number]')!

  numericContainer.hidden = !checked

  // Show loading indicator (in this context it's really a "saving..." indicator)
  const statusIndicator = form.querySelector<HTMLElement>('.js-status-indicator')!
  statusIndicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  statusIndicator.classList.add('status-indicator-loading')

  const flashComponent = form.querySelector<HTMLElement>('.js-push-save-failed-message.flash')!

  try {
    await wants.text()
  } catch (error) {
    // Save failed; reset inputs to last known good values.
    checkbox.checked = checkbox.getAttribute('data-last-saved-value') === 'true'
    numericInput.value = numericInput.getAttribute('data-last-saved-value') ?? '5'

    // Show error indicators.
    statusIndicator.classList.remove('status-indicator-success', 'status-indicator-loading')
    statusIndicator.classList.add('status-indicator-failed')
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    flashComponent.textContent = error.response.text
    flashComponent.hidden = false

    return
  }

  // Save was successful; update last known good values.
  checkbox.setAttribute('data-last-saved-value', checked.toString())
  numericInput.setAttribute('data-last-saved-value', numericInput.value.trim())

  // Show success indicator. Hide any error indicators.
  statusIndicator.classList.remove('status-indicator-failed', 'status-indicator-loading')
  statusIndicator.classList.add('status-indicator-success')
  setTimeout(() => {
    statusIndicator.classList.remove('status-indicator-success')
  }, 4000)

  flashComponent.hidden = true
})

// toggle to Require status checks to pass before merging
on('change', 'input.js-required-status-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('.js-required-statuses')!
  /* eslint-disable-next-line github/no-d-none */
  el.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)
})

// toggle to Require deployments before merging
on('change', 'input.js-required-deployments-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('.js-required-deployments')!
  el.hidden = !(currentTarget as HTMLInputElement).checked
})

// toggle allow fetch upstream when lock branch is enabled
on('change', 'input.js-lock-branch-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('#allow-upstream-fetching')!
  if (!el) return
  el.hidden = !(currentTarget as HTMLInputElement).checked
})

on('change', 'input.js-required-status-checkbox', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branches-item')!
  const badge = container.querySelector<HTMLElement>('.js-required-status-badge')!
  /* eslint-disable-next-line github/no-d-none */
  badge.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)
})

// Add required status check.
on('auto-complete-change', '.js-add-protected-branch-required-status-check', async function ({target: completer}) {
  const autocompleter = completer as AutocompleteElement
  const searchValue = autocompleter.value
  if (!searchValue) return

  const container = autocompleter.closest<HTMLElement>('.js-protected-branch-options')!
  const list = container.querySelector<HTMLElement>('.js-required-status-checks')!
  const found = list.querySelector(`li[data-context='${CSS.escape(searchValue)}']`)
  if (found) {
    found.querySelector<HTMLElement>('.js-required-status-check-context')!.classList.add('user-already-added')
    return
  }

  const dataURL = autocompleter.getAttribute('data-add-url')!
  const url = new URL(dataURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('item', searchValue)
  url.search = params.toString()

  list.append(await fetchSafeDocumentFragment(document, url.toString()))
  list.querySelector('.js-required-status-checks-empty')?.setAttribute('hidden', '')
  container.querySelector('.js-required-status-checks-title-empty')?.setAttribute('hidden', '')
  container.querySelector('.js-required-status-checks-title-any')?.removeAttribute('hidden')
})

// Remove required status check.
on('click', '.js-remove-required-status-check', function ({currentTarget}) {
  const row = currentTarget.closest<HTMLElement>('.js-required-status-check')!
  const parent = row.parentElement!

  row.remove()

  if (parent.childElementCount === 1 && parent.children[0]?.classList.contains('js-required-status-checks-empty')) {
    parent.children[0]?.removeAttribute('hidden')

    const container = parent.parentElement
    container?.querySelector('.js-required-status-checks-title-any')?.setAttribute('hidden', '')
    container?.querySelector('.js-required-status-checks-title-empty')?.removeAttribute('hidden')
  }
})

// Require certain number of reviewers
on('change', 'input.js-reviewers-required-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')
  const el = container?.querySelector<HTMLElement>('.js-reviewers-count')
  if (!el) return

  el.hidden = !(currentTarget as HTMLInputElement).checked

  const input = el.querySelector<HTMLInputElement>(`input#required_approving_review_count_${el.hidden ? 0 : 1}`)
  if (!input) return
  input.click()
  el.focus()
})

// Restrict who can push to this branch.
on(
  'change',
  '.js-authorized-branch-pushers-toggle, input.js-authorized-review-dismisser-toggle',
  function ({currentTarget}) {
    const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')!
    const el = container.querySelector<HTMLElement>('.js-authorized-pushers')!
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)

    el.querySelector<HTMLInputElement>('.js-add-protected-branch-actor input')!.focus()
  },
)

// When creating a new policy restricting who can push, default "create protected" to true.
on('change', '.js-authorized-branch-pushers-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')!
  const createProtectedToggle = container.querySelector<HTMLInputElement>('.js-create-protected-checkbox')

  if (
    (currentTarget as HTMLInputElement).checked &&
    createProtectedToggle?.getAttribute('data-set-create-protected-upon-reveal') === 'true'
  ) {
    createProtectedToggle.checked = true
    createProtectedToggle.removeAttribute('data-set-create-protected-upon-reveal')
  }
})

// When removing a policy restricting who can push, set "create protected" to false.
on('submit', '.js-protected-branch-settings', function ({currentTarget}) {
  const restrictPushersToggle = currentTarget.querySelector<HTMLInputElement>('.js-authorized-branch-pushers-toggle')
  const createProtectedToggle = currentTarget.querySelector<HTMLInputElement>('.js-create-protected-checkbox')

  if (restrictPushersToggle && createProtectedToggle && !restrictPushersToggle.checked) {
    createProtectedToggle.checked = false
  }
})

// Allow users to bypass pull requests
on('change', 'input.js-bypass-pull-request-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')
  if (!container) return
  const el = container.querySelector<HTMLElement>('.js-pull-request-bypassers')
  if (!el) return
  el.hidden = !(currentTarget as HTMLInputElement).checked

  // Remove actors when we toggle the button off
  if (el.hidden) {
    const list = container.querySelector<HTMLElement>('.js-authorized-actors')
    list?.replaceChildren()
  }

  el.querySelector<HTMLInputElement>('.js-add-protected-branch-actor input')?.focus()
})

// Allow users to force push
on('change', 'input[type="radio"][name="allow_force_push"]', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')
  if (!container) return
  const el = container.querySelector<HTMLElement>('.js-allow-force-pushers')
  if (!el) return
  el.hidden = (currentTarget as HTMLInputElement).value !== 'actors'

  // Remove actors when we toggle to everyone
  if (el.hidden) {
    const list = container.querySelector<HTMLElement>('.js-authorized-actors')
    list?.replaceChildren()
  }

  el.querySelector<HTMLInputElement>('.js-add-protected-branch-actor input')?.focus()
})

// Expand force push options
on('change', 'input.js-force-push-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')
  if (!container) return
  const el = container.querySelector<HTMLElement>('.js-restrict-who-pushes')
  if (!el) return
  el.hidden = !(currentTarget as HTMLInputElement).checked

  // Remove actors enable force push checkbox is off
  if (el.hidden) {
    const list = container.querySelector<HTMLElement>('.js-authorized-actors')
    list?.replaceChildren()
  }
})

// Merge queue checkbox: display alert when disabling the merge queue.
on('click', '.js-protected-branch-merge-queue-checkbox', function (event) {
  const checkbox = event.currentTarget as HTMLInputElement
  const msg = checkbox.getAttribute('data-confirm-if-checked') || ''

  // If queue is disabled, but was enabled when the form was rendered,
  // do nothing unless the user confirms they're okay deleting the queue
  if (!checkbox.checked && checkbox.getAttribute('checked') && !confirm(msg)) {
    event.preventDefault()
    return
  }

  const container = checkbox.closest<HTMLElement>('.js-protected-branch-settings')
  if (!container) return
  const el = container.querySelector<HTMLElement>('.js-protected-branch-merge-queue-settings')
  if (!el) return
  el.hidden = !checkbox.checked
})

// Selectively enable / disable the merge queue merge strategy when require linear history is changed
on('click', '.js-protected-branch-require-linear-history-checkbox', function (event) {
  const checkbox = event.currentTarget as HTMLInputElement
  const merge_strategy = document
    .querySelector<HTMLElement>('[name=merge_queue_merge_method][value=MERGE]')
    ?.closest<HTMLElement>('.js-protected-branch-merge-queue-method-radio')
  const merge_strategy_input = merge_strategy?.querySelector<HTMLInputElement>('input')
  const merge_strategy_warning = merge_strategy?.querySelector<HTMLInputElement>('.unavailable-merge-method')
  if (!merge_strategy || !merge_strategy_input || !merge_strategy_warning) return

  if (checkbox.checked) {
    // Disable the "merge" strategy when linear history is enabled
    merge_strategy.tabIndex = -1
    merge_strategy.ariaChecked = 'false'
    merge_strategy.classList.add('disabled')
    merge_strategy_input.disabled = true
    merge_strategy_warning.hidden = false
  } else {
    // Enable the "merge" strategy when linear history is disabled
    merge_strategy.tabIndex = 0
    merge_strategy.classList.remove('disabled')
    merge_strategy_input.disabled = false
    merge_strategy_warning.hidden = true
  }
})

// Selectively enable / disable "require linear history" based on chosen merge queue commit strategy
on('change', '.js-protected-branch-merge-queue-method-radio input', function (event) {
  const radio = event.currentTarget as HTMLInputElement
  const block_merge_commits = document.querySelector<HTMLInputElement>('[name=block_merge_commits]')
  const linear_history_warning = document.querySelector<HTMLElement>('.js-protected-branch-linear-history-warning')
  if (!block_merge_commits || !linear_history_warning) return

  if (radio.value === 'MERGE') {
    // Disable linear history when the merge commit strategy is merge
    block_merge_commits.disabled = true
    block_merge_commits.checked = false
    linear_history_warning.hidden = false
  } else {
    // Allow linear history if when the merge commit strategy is squash or rebase
    block_merge_commits.disabled = false
    linear_history_warning.hidden = true
  }
})

// Enforce all configured restrictions for administrators.
on('change', '.js-protected-branch-include-admin-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const permissions = container.querySelectorAll('.js-protected-branch-admin-permission')
  for (const el of permissions) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('d-none')
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('active', !el.classList.contains('d-none'))
  }
})

// Update list of authorized actors to include MQ bot when MQ is enabled
on('change', '.js-protected-branch-merge-queue-checkbox', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const permissions = container.querySelectorAll('.js-protected-branch-authorized-actors')
  for (const el of permissions) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('d-none')
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('active', !el.classList.contains('d-none'))
  }
})

// Toggle the maximum number of allowed pushers.
function toggleMaxAllowedPushers(container: HTMLElement) {
  const allowedPushers = container.querySelector<HTMLElement>('.js-authorized-pushers')
  if (!allowedPushers?.getAttribute('data-limit')) return
  const allowedPushersLimit = parseInt(allowedPushers.getAttribute('data-limit') || '')
  const allowedPushersCount = allowedPushers.querySelectorAll('.js-authorized-user-or-team').length
  allowedPushers.classList.toggle('at-limit', allowedPushersCount >= allowedPushersLimit)
}

// Grant branch push access to a user.
on('auto-complete-change', '.js-add-protected-branch-actor', async function ({target: completer}) {
  const autocompleter = completer as AutocompleteElement
  const searchValue = autocompleter.value
  if (!searchValue) return
  autocompleter.value = ''

  const container = autocompleter.closest<HTMLElement>('.js-protected-branch-options')!
  const list = container.querySelector<HTMLElement>('.js-authorized-actors')!
  const found = list.querySelector(`div[data-user-or-team-name='${searchValue}']`)
  if (found) {
    found.querySelector<HTMLElement>('.js-protected-branch-pusher')!.classList.add('user-already-added')
    return
  }

  const dataURL = autocompleter.getAttribute('data-add-url')!
  const url = new URL(dataURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('item', searchValue)
  url.search = params.toString()

  list.append(await fetchSafeDocumentFragment(document, url.toString()))
  toggleMaxAllowedPushers(container)
})

// Remove users who are authorized to push to a specific branch.
on('click', '.js-remove-authorized-user-or-team', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')!
  const userOrTeam = currentTarget.closest<HTMLElement>('.js-authorized-user-or-team')!

  if (userOrTeam.nextElementSibling) {
    userOrTeam.nextElementSibling.querySelector<HTMLElement>('.js-remove-authorized-user-or-team')!.focus()
  } else if (userOrTeam.previousElementSibling) {
    userOrTeam.previousElementSibling.querySelector<HTMLElement>('.js-remove-authorized-user-or-team')!.focus()
  } else {
    container.querySelector<HTMLElement>('.js-add-protected-branch-actor input')!.focus()
  }

  userOrTeam.remove()
  toggleMaxAllowedPushers(container)
})

on('click', '.js-pages-cname-save-btn', function () {
  const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-cname-remove-btn')!
  if (removeButton) {
    removeButton.disabled = false
  }
})

on('click', '.js-pages-custom-subdomain-save-btn', function () {
  const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-custom-subdomain-remove-btn')!
  if (removeButton) {
    removeButton.disabled = false
  }
})

// disable pages-cname save button until value changes
observe('#pages-cname-field', {
  constructor: HTMLInputElement,
  add(el) {
    addThrottledInputEventListener(el, function () {
      const button = document.querySelector<HTMLButtonElement>('.js-pages-cname-save-btn')!
      const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-cname-remove-btn')!

      if (el.value === '' && removeButton) {
        button.disabled = true
      } else {
        button.disabled = el.value === el.defaultValue
      }
    })
  },
})

// disable pages-custom-subdomain save button until value changes
observe('#pages-custom-subdomain-field', {
  constructor: HTMLInputElement,
  add(el) {
    addThrottledInputEventListener(el, function () {
      const button = document.querySelector<HTMLButtonElement>('.js-pages-custom-subdomain-save-btn')!
      const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-custom-subdomain-remove-btn')!

      if (el.value === '' && removeButton) {
        button.disabled = true
      } else {
        button.disabled = el.value === el.defaultValue
      }
    })
  },
})

// Query certificate status every 15 seconds.
const certificateQueryDuration = 15 * 1000
setTimeout(checkCertificateStatus, certificateQueryDuration)
async function checkCertificateStatus() {
  const content = document.querySelector('.js-pages-certificate-status')
  if (content) {
    const url = content.getAttribute('data-status-url')!
    const fragment = await fetchSafeDocumentFragment(document, url)
    content.replaceWith(fragment)
    setTimeout(checkCertificateStatus, certificateQueryDuration)
  }
}

// Query DNS 2 seconds after page load
setTimeout(checkDomainStatus, 2000)
async function checkDomainStatus() {
  const content = document.querySelector('.js-pages-domain-status')
  if (content) {
    const state = content.getAttribute('data-domain-state')!

    if (!['pending', 'queued'].includes(state)) return // if state if not pending or queued, stop checking.

    const url = content.getAttribute('data-domain-status-url')!
    const fragment = await fetchSafeDocumentFragment(document, url)
    content.replaceWith(fragment)
    // Check domain status again in 5 seconds
    setTimeout(checkDomainStatus, 5000)
  }
}

function getPageSourceSubmitBtn() {
  return document.querySelector<HTMLButtonElement>('.js-update-page-source-btn')!
}

function getPageSource(name: string) {
  const source = document.querySelector(`input[name=${name}]:checked`)
  if (source) {
    return (source as HTMLInputElement).value
  }
  return null
}

// This flash warning is only created if a gh-pages branch exists in a project repo or if we have a user repo
function getPageWarningMessage() {
  const flashWarning = document.querySelector('.js-disable-page-warning')
  if (flashWarning) {
    return flashWarning as HTMLElement
  }
  return null
}

const pageSourceBranch = getPageSource('source')
const pageSourceDir = getPageSource('source_dir')

on('change', '.js-select-branch', event => {
  const input = event.target as HTMLInputElement
  if (input.name !== 'source') return
  const sourceDirSelector = document.querySelector<HTMLElement>('.js-select-source-dir')!
  const branchIcon = document.querySelector<HTMLElement>('.js-branch-icon')!
  if (input.value !== '') {
    branchIcon.hidden = false
    sourceDirSelector.hidden = false
  } else {
    branchIcon.hidden = true
    sourceDirSelector.hidden = true
  }

  const warningObject = getPageWarningMessage()
  if (warningObject != null) {
    if (input.value === '') {
      warningObject.hidden = false
    } else {
      warningObject.hidden = true
    }
  }

  if (input.value !== pageSourceBranch) {
    getPageSourceSubmitBtn().disabled = false
    //If 'None' source is selected
    if (input.value === '' && warningObject != null) {
      getPageSourceSubmitBtn().disabled = true
    }
  } else {
    if (pageSourceDir === getPageSource('source_dir') || pageSourceBranch === '') {
      getPageSourceSubmitBtn().disabled = true
    }
  }
})

on('change', '.js-select-source-dir', event => {
  const input = event.target as HTMLInputElement
  if (input.name !== 'source_dir') return
  const sourceDir = input.value
  if (sourceDir !== pageSourceDir) {
    getPageSourceSubmitBtn().disabled = false
  } else {
    if (getPageSource('source') === pageSourceBranch) {
      getPageSourceSubmitBtn().disabled = true
    }
  }
})
