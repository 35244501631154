import {create, supported, parseCreationOptionsFromJSON} from '@github/webauthn-json/browser-ponyfill'
import type {
  CredentialCreationOptionsJSON,
  RegistrationPublicKeyCredential,
} from '@github/webauthn-json/browser-ponyfill'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {parseHTML} from '@github-ui/parse-html'
import {remoteForm} from '@github/remote-form'
import sudo from '@github-ui/sudo'
import {toggleDetailsTarget} from '../behaviors/details'
import {iuvpaaSupportLevel} from '@github-ui/webauthn-support-level'

interface RegistrationResponse {
  nickname?: string
  passkey_type?: string
  registered_passkeys_count?: number
  registered_security_keys_count?: number
  webauthn_register_request?: JSON
  registration?: string
  error?: string
}

let originalErrorMessage: string | null

function togglePasskeyRegistrationButton(hidden: boolean) {
  const registerButton = document.querySelector<HTMLInputElement>('.js-trusted-device-registration-button')!
  registerButton.hidden = hidden
}

/******* Reading/writing registration form data ********/

function u2fForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>('.js-add-u2f-registration-form')
}

const registerRequestDataAttribute = 'data-webauthn-register-request'

function updateDataAttribute(data: RegistrationResponse): void {
  if (data.webauthn_register_request) {
    u2fForm()?.setAttribute(registerRequestDataAttribute, JSON.stringify(data.webauthn_register_request))
  }
}

// exporting as an object that can be stubbed for testing purposes
export const helper = {
  reloadPage: (anchor?: string) => {
    if (anchor) {
      window.location.replace(`#${anchor}`)
    }
    window.location.reload()
  },
}

/******* DOM listeners ********/

// delete passkey
remoteForm('.js-passkey-registration-delete', async function (form, wants) {
  const registration = form.closest<HTMLElement>('.js-passkey-registration')!
  const button = registration.querySelector<HTMLButtonElement>('.js-passkey-registration-delete-button')!
  // Trigger a spinner while deleting a U2F device
  if (button) {
    button.disabled = true
  } else {
    registration.classList.add('is-sending')
  }

  const response = await wants.json()

  if (response.json.registered_passkeys_count === 0) {
    // We need to refresh the UI to update preferred 2FA method
    helper.reloadPage()
  } else {
    // Remove the U2F registration from the list once deleted
    updateDataAttribute(response.json)
    registration.remove()
    if (button && response.json.registered_passkeys_count === 0) {
      const passkeysView = document.querySelector<HTMLElement>('.js-passkeys-view')!
      passkeysView.hidden = true
      const emptyView = document.querySelector<HTMLElement>('.js-passkeys-empty-view')!
      emptyView.hidden = false
    }
  }
})

// delete security key
remoteForm('.js-u2f-registration-delete', async function (form, wants) {
  const registration = form.closest<HTMLElement>('.js-u2f-registration')!
  const button = registration.querySelector<HTMLButtonElement>('.js-u2f-registration-delete-button')!
  // Trigger a spinner while deleting a U2F device
  if (button) {
    button.disabled = true
  } else {
    registration.classList.add('is-sending')
  }

  const response = await wants.json()

  if (response.json.registered_security_keys_count === 0) {
    // We need to refresh the UI to update preferred 2FA method and two_factor_summary_row labels
    helper.reloadPage()
  } else {
    // Remove the U2F registration from the list once deleted
    updateDataAttribute(response.json)
    registration.remove()
  }
})

remoteForm('.js-passkey-update', async function (form, wants) {
  const container = form.closest<HTMLElement>('.js-details-container')!
  const errorDisplay = container.querySelector<HTMLElement>('.js-passkey-edit-form-error')!
  const showNickname = container.querySelector<HTMLElement>('.js-passkey-nickname')!

  let response
  try {
    response = await wants.json()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    const errorText = error.response?.json?.error
    if (errorText) {
      errorDisplay.textContent = errorText
    }
    errorDisplay.hidden = false
  }

  if (!response) return

  toggleDetailsTarget(container)
  errorDisplay.hidden = true
  const data = response.json
  if (data?.nickname) {
    showNickname.textContent = data.nickname
  }
  for (const field of form.elements) {
    if (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) {
      field.defaultValue = field.value
    }
  }
})

// Reveal the form the input when 'register a new device' is clicked
on('click', '.js-add-u2f-registration-link', function () {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-active')
  newDeviceBox.classList.remove('is-showing-error')
  const nickname = document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-field')!
  nickname.value = ''
  nickname.focus()
})

/******* Error Handling ********/

// Reveal the error message with optional custom text content.
function showError(text?: string) {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-showing-error')
  newDeviceBox.classList.remove('is-sending')

  const error_message = newDeviceBox.querySelector<HTMLElement>('.js-webauthn-registration-error-message')!
  error_message.textContent = ''
  for (const el of newDeviceBox.querySelectorAll<HTMLElement>('.js-webauthn-message')) {
    el.hidden = true
  }

  const error_container = newDeviceBox.querySelector<HTMLElement>('.js-webauthn-registration-error')!
  error_container.hidden = false
  error_message.textContent = originalErrorMessage

  const error_message_info = newDeviceBox.querySelector<HTMLElement>('.js-webauthn-registration-error-message-info')!
  error_message_info.textContent = text ?? ''
  error_message_info.hidden = false
}

function toggleDuplicatePasskeyMessage(hidden: boolean) {
  const duplicateMessage = document.querySelector<HTMLElement>('.js-passkey-duplicate-message')!
  duplicateMessage.hidden = hidden
}

function toggleUpgradeConflictPasskeyMessage(hidden: boolean) {
  const conflictMessage = document.querySelector<HTMLElement>('.js-webauthn-registration-upgrade-conflict-message')!
  conflictMessage.hidden = hidden
}

/******* Registration ********/

function resetForm() {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.remove('is-sending', 'is-active')
}

// Persist the client's registration response on the server.
async function saveRegistration(registerResponse: RegistrationPublicKeyCredential): Promise<void> {
  const sudoPassed = await sudo()
  if (!sudoPassed) {
    throw new Error('sudo failed')
  }

  const for_passkey = document.querySelector<HTMLButtonElement>('.js-passkey')
  const form = document.querySelector<HTMLFormElement>('.js-add-u2f-registration-form')!

  ;(form.elements.namedItem('response') as HTMLInputElement).value = JSON.stringify(registerResponse)

  let response
  try {
    response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
    })
    if (!response.ok) {
      throw new Error('Response was not ok')
    }
    const data: RegistrationResponse = await response.clone().json()

    updateDataAttribute(data)
    resetForm()
    if (for_passkey) {
      handlePasskeySave(data)
    } else {
      handleSecurityKeySave(data)
    }
  } catch {
    if (response) {
      const errorResponse: RegistrationResponse = await response.json()
      updateDataAttribute(errorResponse)
      showError(errorResponse.error)
    } else {
      showError()
    }
  }
}

function handleSecurityKeySave(data: RegistrationResponse): void {
  const htmlString = data.registration
  if (!htmlString) {
    return
  }

  const registrations = document.querySelector('.js-u2f-registrations') as HTMLElement
  registrations.append(parseHTML(document, htmlString))
  if (data.registered_security_keys_count === 1) {
    // for the first registered security key, we need to refresh the UI to update preferred 2FA method and two_factor_summary_row labels
    helper.reloadPage('webauthn')
  }
}

function handlePasskeySave(data: RegistrationResponse) {
  const defer = document.querySelector<HTMLDivElement>('.js-new-u2f-registration-never-ask')
  if (defer) {
    defer.hidden = true
  }
  document.querySelector<HTMLDivElement>('.js-new-u2f-registration')!.hidden = true
  document.querySelector<HTMLDivElement>('.js-webauthn-confirm')!.hidden = true
  document.querySelector<HTMLDivElement>('.js-new-u2f-registration-success')!.hidden = false
  const nickname = document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-field')!
  switch (data.passkey_type) {
    case 'synced': {
      document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-advice-synced')!.hidden = false
      break
    }
    case 'hardware': {
      document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-advice-hardware')!.hidden = false
      break
    }
    case 'bound':
    case 'upgraded': {
      nickname.value = data.nickname || ''
      break
    }
  }
  nickname.focus()
}

function showSpinner() {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-sending')
  newDeviceBox.classList.remove('is-showing-error')
}

function hideSpinner() {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.remove('is-sending')
}

// Ask the device to register itself when the user taps its button.
export async function waitForWebauthnDevice(): Promise<void> {
  const passkey = document.querySelector<HTMLButtonElement>('.js-passkey')
  if (passkey) {
    await waitForPasskeyGesture()
  } else {
    await waitForSecurityKeyGesture()
  }
}

export async function waitForPasskeyGesture(): Promise<void> {
  try {
    togglePasskeyRegistrationButton(true)
    toggleDuplicatePasskeyMessage(true)
    toggleUpgradeConflictPasskeyMessage(true)
    showSpinner()

    const registerRequestJSON: CredentialCreationOptionsJSON = JSON.parse(
      u2fForm()!.getAttribute(registerRequestDataAttribute)!,
    )
    const registerResponse = await create(parseCreationOptionsFromJSON(registerRequestJSON))
    await saveRegistration(registerResponse)
  } catch (error) {
    hideSpinner()

    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.name === 'InvalidStateError') {
      if (userHasSecurityKeys()) {
        // if the user has security keys show the confirm
        document.querySelector<HTMLButtonElement>('.js-new-u2f-registration')!.hidden = true
        document.querySelector<HTMLButtonElement>('.js-webauthn-confirm')!.hidden = false
        document.querySelector<HTMLButtonElement>('.js-webauthn-confirm-button')!.focus()
      } else {
        // if the user has no security keys and they have passkeys, we can assume it's a duplicate Passkey
        if (userHasPasskeys()) {
          togglePasskeyRegistrationButton(false)
          toggleDuplicatePasskeyMessage(false)
        } else {
          // if the user has no passkeys, some other error has occurred (shouldn't happen)
          togglePasskeyRegistrationButton(false)
          showError()
        }
      }
    } else {
      togglePasskeyRegistrationButton(false)
      showError()
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      if (error.name !== 'NotAllowedError' && userHasSecurityKeys() && (await iuvpaaSupportLevel()) === 'unsupported') {
        toggleUpgradeConflictPasskeyMessage(false)
      }
      throw error
    }
  }
}

export async function waitForSecurityKeyGesture(): Promise<void> {
  try {
    showSpinner()
    const registerRequestJSON: CredentialCreationOptionsJSON = JSON.parse(
      u2fForm()!.getAttribute(registerRequestDataAttribute)!,
    )
    const registerResponse = await create(parseCreationOptionsFromJSON(registerRequestJSON))
    await saveRegistration(registerResponse)
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.name === 'InvalidStateError') {
      showError()
      // and show duplicate security key error message
      document.querySelector<HTMLElement>('.js-webauthn-duplicate-registration-message')!.hidden = false
    } else {
      showError()
      throw error
    }
  }
}

on('click', '.js-add-u2f-registration-retry', function () {
  waitForWebauthnDevice()
})

// Reveal the passkey registration form.
observe('.js-add-u2f-registration-form.for-trusted-device', {
  constructor: HTMLFormElement,
  add(el) {
    el.hidden = false
  },
})

// Handle the creation of a new registration.
on('submit', '.js-add-u2f-registration-form', function (event) {
  event.preventDefault()
  waitForWebauthnDevice()
})

observe('.js-webauthn-box', function (el) {
  // Remove the 'unsupported' message.
  el.classList.toggle('available', supported())
})

observe('.js-webauthn-registration-error-message', function (el) {
  originalErrorMessage = el.textContent
  el.textContent = ''
})

on('auto-check-success', '.js-u2f-registration-nickname-field', function () {
  const continueButton = document.querySelector<HTMLInputElement>('.js-trusted-device-continue')!
  continueButton.disabled = false
})

on('auto-check-error', '.js-u2f-registration-nickname-field', function () {
  const continueButton = document.querySelector<HTMLInputElement>('.js-trusted-device-continue')!
  continueButton.disabled = true
})

function userHasSecurityKeys() {
  const securityKeyElem = document.querySelector<HTMLInputElement>('#existing_security_key_count')
  return securityKeyElem && parseInt(securityKeyElem.value) > 0
}

function userHasPasskeys() {
  const passkeyElem = document.querySelector<HTMLInputElement>('#existing_passkey_count')
  return passkeyElem && parseInt(passkeyElem.value) > 0
}
