import {requestSubmit} from '@github-ui/form-utils'
import {controller, target} from '@github/catalyst'
import '@oddbird/popover-polyfill'
import type {IncludeFragmentElement} from '@github/include-fragment-element'
import type {
  ItemActivatedEvent,
  SelectPanelExperimentalElement,
} from '../../../../components/primer/experimental/select-panel-element'

@controller
class PagesJekyllConfig extends HTMLElement {
  @target includeFragment: IncludeFragmentElement
  @target form: HTMLFormElement
  @target submitButton: HTMLButtonElement
  @target branchSelector: SelectPanelExperimentalElement
  @target folderSelector: SelectPanelExperimentalElement

  selectedBranch: string
  selectedFolder: string

  connectedCallback() {
    this.selectedFolder = this.getAttribute('data-initial-subdir') || '/'
    this.selectedBranch = this.getAttribute('data-initial-branch') || 'None'

    this.#refresh()
  }

  #refresh() {
    this.setBranchAndPath()
    this.setSubmitButton()
  }

  selectFolder(event: CustomEvent<ItemActivatedEvent>) {
    const activeItem = event.detail.item
    if (!activeItem) return
    const itemLabel = activeItem.getAttribute('data-name')
    if (itemLabel == null) return
    const selectFolder = itemLabel.trim()
    this.selectedFolder = selectFolder
    this.#refresh()
  }

  selectBranch(event: CustomEvent<ItemActivatedEvent>) {
    const activeItem = event.detail.item
    if (!activeItem) return
    const itemLabel = activeItem.getAttribute('data-name')
    if (itemLabel == null) return
    const selectedLabel = itemLabel.trim()
    this.selectedBranch = selectedLabel
    this.#refresh()
  }

  setBranchAndPath() {
    const sourceDirSelector = this.querySelector<HTMLSelectElement>('.js-jekyll-source-dir')
    const buttonVisual = this.querySelector<HTMLSelectElement>('.Button-leadingVisual')
    if (this.selectedBranch === 'None' || this.selectedBranch === '') {
      sourceDirSelector?.setAttribute('hidden', '')
      buttonVisual?.setAttribute('hidden', '')
    } else {
      sourceDirSelector?.removeAttribute('hidden')
      buttonVisual?.removeAttribute('hidden')
    }
  }

  setSubmitButton(): void {
    if (this.submitButton.getAttribute('data-always-enabled') === 'true') return
    const initialBranch = this.getAttribute('data-initial-source')
    const initialFolder = this.getAttribute('data-initial-subdir')
    const disabled =
      (initialBranch === this.selectedBranch || (initialBranch === 'gh-pages' && this.selectedBranch === 'None')) &&
      (initialFolder === this.selectedFolder || (!initialFolder && this.selectedFolder === '/'))
    if (disabled) {
      this.submitButton.setAttribute('disabled', '')
    } else {
      this.submitButton.removeAttribute('disabled')
    }
  }

  submitForm() {
    // append input to form
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'source'
    input.value = this.selectedBranch
    this.form.appendChild(input)

    // append the subdir input to form
    const subdirInput = document.createElement('input')
    subdirInput.type = 'hidden'
    subdirInput.name = 'source_dir'
    subdirInput.value = this.selectedFolder
    this.form.appendChild(subdirInput)
    requestSubmit(this.form)
  }
}

if (!window.customElements.get('pages-jekyll-config')) {
  window.customElements.define('pages-jekyll-config', PagesJekyllConfig)
}
