import {fetchSafeDocumentFragment} from '@github-ui/fetch-utils'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '@github-ui/form-utils'

// Delete a token or revoke an application
remoteForm('.js-revoke-access-form', async function (form, wants) {
  await wants.html()
  const id = form.getAttribute('data-id')!
  const type = form.getAttribute('data-type-name')!
  const target = document.querySelector<HTMLElement>(`.js-revoke-item[data-type='${type}'][data-id='${id}']`)!
  target.remove()
  if (target.classList.contains('new-token')) {
    document.querySelector<HTMLElement>('.js-flash-new-token')!.remove()
  }
})

// Remove an oauth application image
on('click', '.js-delete-oauth-application-image', function () {
  const form = document.querySelector<HTMLFormElement>('.js-delete-oauth-application-image-form')!
  requestSubmit(form)
})

// Add a new oauth application callback url form
on('click', '.js-new-callback', function (event) {
  event.preventDefault()
  const target = event.currentTarget

  const container = target.closest<HTMLElement>('.js-callback-urls')!
  const urlForm = container.querySelector<HTMLElement>('.js-callback-url')!
  const newUrlForm = urlForm.cloneNode(true)
  if (!(newUrlForm instanceof HTMLElement)) return

  newUrlForm.classList.remove('is-default-callback')
  const formInput = newUrlForm.querySelector<HTMLInputElement>('input')!
  formInput.value = ''

  container.classList.add('has-many')
  target.parentNode!.insertBefore(newUrlForm, target)
})

// Delete an oauth application callback url
on('click', '.js-delete-callback', function (event) {
  event.preventDefault()

  const target = event.currentTarget
  const callbackUrl = target.closest<HTMLElement>('.js-callback-url')!
  callbackUrl.remove()
  const container = document.querySelector<HTMLElement>('.js-callback-urls')!
  const urls = container.querySelectorAll<HTMLElement>('.js-callback-url')
  if (urls.length <= 1) {
    container.classList.remove('has-many')
  }
})

on('click', '.js-oauth-application-allowlist .js-deny-this-request', function (event) {
  const target = event.currentTarget
  const targetSibling = target.nextElementSibling as HTMLInputElement

  targetSibling.value = 'denied'
  requestSubmit(target.closest<HTMLFormElement>('.js-org-application-access-form')!)
})

// Fetch keys list.
async function fetchKeysList(root: HTMLElement) {
  if (!root) return
  const url = root.getAttribute('data-url')
  if (!url) return

  let fragment
  try {
    fragment = await fetchSafeDocumentFragment(document, url)
    root.textContent = ''
    root.append(fragment)
  } catch {
    // fall back to reloading the page
    window.location.reload()
  }
}

// Generating new integration key
on('click', '.js-generate-integration-key', function () {
  const wrapper = document.querySelector<HTMLElement>('.js-integration-key-management-wrapper')!
  wrapper.classList.add('downloading')

  if (wrapper.classList.contains('js-integration-key-multi')) {
    wrapper.classList.add('has-keys')

    setTimeout(function () {
      const root = document.querySelector<HTMLElement>('.js-integration-keys-container')!
      if (root) fetchKeysList(root)
    }, 1000)
  }
})

// Delete an integration key; disable delete button for last remaining key
remoteForm('.js-remove-integration-key', async function (form, wants) {
  try {
    await wants.text()
  } catch (error) {
    // This is not the error we expected, re-throw since we don't know how to
    // handle it.
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (!error.response) throw error

    form.closest<HTMLElement>('details')!.removeAttribute('open')

    const wrapper = document.querySelector<HTMLElement>('.js-integration-key-management-wrapper')!
    const errContainer = wrapper.querySelector<HTMLElement>('.js-error-container')!

    const errLabel = errContainer.querySelector<HTMLElement>('.js-error-message')!
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    switch (error.response.status) {
      case 500:
        errLabel.textContent = 'Oops, something went wrong.'
        break
      case 404:
        errLabel.textContent = 'Oops, that key could not be found.'
        break
      default:
        // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
        errLabel.textContent = error.response.json.message
    }

    wrapper.classList.add('error')
    return
  }

  const keyId = form.getAttribute('data-key-id')!
  const key = document.getElementById(keyId)

  if (key) {
    const root = key.closest<HTMLElement>('.js-integration-keys-container')!
    if (root) fetchKeysList(root)
  }
})

on('click', '.js-error-dismiss', function () {
  const root = document.querySelector<HTMLElement>('.js-integration-keys-container')!
  if (root) fetchKeysList(root)
})

on('change', '.js-checkbox-scope', function (event) {
  const target = event.currentTarget as HTMLInputElement
  if (target.checked) {
    addToSelectedBy(target, 'self')
  } else {
    removeFromSelectedBy(target, 'self')
  }

  // Check scope subsets if the parent is checked
  toggleScopeSubsets(target)
})

function toggleScopeSubsets(target: HTMLInputElement) {
  const container = target.closest<HTMLElement>('.js-check-scope-container')!
  const scopes = container.querySelectorAll<HTMLInputElement>('.js-checkbox-scope')

  for (const checkbox of scopes) {
    if (checkbox !== target) {
      if (target.checked) {
        addToSelectedBy(checkbox, target.value)
      } else removeFromSelectedBy(checkbox, target.value)
    }
  }
}

// Some scopes have related scopes. Auto-select those scope families.
on('change', '[data-check-related-scopes]', function (event) {
  const parent = event.currentTarget as HTMLInputElement
  const scopes = parent.getAttribute('data-check-related-scopes')!.split(' ')

  for (const scope of scopes) {
    const childScopes = document.querySelectorAll<HTMLInputElement>(`[data-scope-for="${scope}"] .js-checkbox-scope`)

    for (const checkbox of childScopes) {
      if (parent.checked) {
        addToSelectedBy(checkbox, parent.value)
      } else removeFromSelectedBy(checkbox, parent.value)
    }
  }
})

async function enabledCheckedAndDisabledScopes(event: Event) {
  const newTokenForm = event.currentTarget as HTMLFormElement
  const checkedParentSelector = '.js-checkbox-scope:checked:disabled'
  const checkedParentScopes = newTokenForm.querySelectorAll<HTMLInputElement>(checkedParentSelector)
  for (const scope of checkedParentScopes) {
    scope.disabled = false
  }
}

// Enable all the checked-disabled parent scopes on submit so they make it to the controller
on('submit', '#new_oauth_access', enabledCheckedAndDisabledScopes)
on('submit', '.js-edit-oauth-access', enabledCheckedAndDisabledScopes)

function removeFromSelectedBy(target: HTMLInputElement, actor: string) {
  let selectedBy = fetchSelectedByArray(target)
  selectedBy = removeItemFromArray(selectedBy, actor)
  target.setAttribute('data-selected-by', selectedBy.join(' '))
  updateDependencyGraph(target)
}

function addToSelectedBy(target: HTMLInputElement, actor: string) {
  const selectedBy = fetchSelectedByArray(target)
  selectedBy.push(actor)
  target.setAttribute('data-selected-by', selectedBy.join(' '))
  updateDependencyGraph(target)
}

function updateDependencyGraph(target: HTMLInputElement) {
  const selectedBy = target.getAttribute('data-selected-by')
  target.checked = selectedBy !== ''
  target.disabled = target.checked && selectedBy !== 'self'
}

function fetchSelectedByArray(target: HTMLInputElement) {
  if (target.getAttribute('data-selected-by')) {
    return target.getAttribute('data-selected-by')!.split(' ')
  }
  return []
}

function removeItemFromArray(arr: string[], value: string) {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}
