import {attr, controller, target} from '@github/catalyst'

@controller
class PublicIpElement extends HTMLElement {
  @target checkbox: HTMLInputElement
  @target description: HTMLDivElement
  @target labelSection: HTMLDivElement

  static attrPrefix = ''
  @attr isAllowed = false

  connectedCallback() {
    this.isAllowed = this.hasAttribute('is-allowed')
  }

  checkboxChanged() {
    const publicIpEnabled = this.checkbox.checked
    const isAllowed = this.isAllowed
    if (!isAllowed && !publicIpEnabled) {
      this.checkbox.setAttribute('disabled', 'disabled')
      this.description.classList.remove('color-fg-muted')
      this.labelSection.classList.add('color-fg-subtle')
    }
  }
}
